import { RouteName } from "../../route";

export const LandingLanguage = {
    en: {
        navbar: {
            1: "Who We Are",
            2: "Our Impact",
            2.1: "Capital Investment",
            2.2: "EPC",
            2.3: "Renewable Energy",
            2.4: "Industrial",
            2.5: "Integrated Telecomunication",
            2.6: "Real Estate & Propertity",
            2.7: "Developer",
            2.8: "Research and Development",
            3: "Business Highlights",
            4: "Sustainability",
            4.1: "Commitment",
            4.2: "Our Strategic at QTC",
            4.3: "Partnerships",
            5: "Media",
            5.1: "Press Release",
            5.2: "Marketing Assets",
            6: "Contact",
            7: "Career",
            7.1: "Career Oportunities",
            7.2: "Why Join Us",
            7.3: "Explore Our Content Job",
        },
        [RouteName.main]: {
            1: "Quantum Trans Capital",
            2.1: "Sharing Investment Opportunities",
            2.2: "All Around Indonesia",
            3: "Who We Are",
            4.1: "Connecting Global Investors and Real Time",
            4.2: "Sustainable Projects in Indonesia",
            5.1: "A Resilient and Secure",
            5.2: "Holding Company with",
            5.3: "Diversified Energy Related",
            5.4: "Operating Assets",
            6: "Quantum Trans Capital, we drive positive change in Indonesia's economy by bridging visionary projects with strategic investors.",
            7: "Our focus on capital investment, renewable energy, real estate, and industrial needs fosters growth, innovation, and sustainability. As a catalyst for progress, Quantum Trans Capital connects opportunities and shapes a brighter future.",
            8: "Our Impact",
            9: "Quantum Trans Capital is an Indonesian company specializing in facilitating and funding investment opportunities across diverse sectors",
            10: "Business Highlights",
            11: "Quantum Trans Capital has brought leading projects, destinations and untapped opportunities to market through key partnerships and investments in the region and beyond",
            12: "Renewable Energy",
            13: "Sustainability",
            14: "<b>Quantum Trans Capital,</b> sustainability is a fundamental principle underlying our strategic direction and operational excellence.",
            15: "We are committed to delivering sustainable value for our stakeholders, while improving the performance of each line company.",
            16: "Our business is guided by sustainability principles that shape our strategic direction and operational excellence.",
            17: "Sustainability",
            18: "Keep track of all the exciting news and opportunities as wegrow our network by entering new markets, forging new partnerships, and our latest resources",
            19: "Explore our media centre",
            20: "Press releases and resources",
        },
        [RouteName.about]: {
            1.1: "Connect evolving needs with",
            1.2: "sustainable innovation opportunities",
            2: "Who we are",
            3.1: "Investing for Sustainable",
            3.2: "Growth in Indonesia",
            4: "Quantum Trans Capital is a dynamic and versatile global company that specializes in bridging and funding investment opportunities across multiple sectors.",
            5: "Quantum Trans Capital is also a PMA from Hong Kong.",
            6: "Our mission is to facilitate growth, innovation and sustainable development by connecting investors, entrepreneurs and projects.",
            7: "With a focus on capital, renewable energy, real estate, and other industrial needs, Quantum Trans Capital plays an important role in shaping the global economic landscape.",
            8: "Our Vision",
            9: "Quantum Trans Capital envisions a prosperous Indonesia where sustainable investments drive economic growth, improve livelihoods, and protect the environment.",
            10: "We strive to be a catalyst for positive change, fostering innovation and resilience.",
            11: "Our Mission",
            12: "Quantum Trans Capital is committed to transforming Indonesia's economic landscape through strategic, high-impact investments.",
            13: "Our mission is to bridge the gap between visionary projects and strategic investors, fostering sustainable growth, financial innovation, and long-term value creation. Leveraging our expertise in capital markets, asset management, and sector-focused financing within renewable energy, real estate, and industrial development, we are dedicated to delivering measurable returns while ensuring responsible growth.",
            14: "Quantum Trans Capital stands as a catalyst for progress, connecting opportunities and shaping a brighter future.",
            15: "Quantum Trans Capital Leadership",
            16: "Our people are our",
            17: "greatest strength",
            18: "Quantum Trans Capital focuses on building a global ecosystem that drives growth, technology and innovation opportunities for shareholders, companies and countries.",
        },
        [RouteName.ourImpact]: {
            1.1: "Driving Sustainable Innovation:",
            1.2: "Quantum Trans Capital's Impact",
            2: "Our Impact",
            3: "Through the management of a diversified and strategically integrated asset portfolio, Quantum Trans Capital is dedicated to fostering sustainable economic growth and advancing innovation across key industrial sectors. Guided by a disciplined investment strategy with a strong emphasis on ESG principles, we focus on creating long-term value for stakeholders while generating meaningful societal and environmental benefits.",
            4: "The companies within Quantum Trans Capital's portfolio represent industry leaders recognized for their bold, innovative approaches. Committed to advancing technological capabilities and implementing best practices, they drive positive, far-reaching impacts across diverse sectors. By prioritizing strategic partnerships and sustainability, Quantum Trans Capital consistently seeks solutions to address modern market challenges, contributing tangibly to inclusive and sustainable economic growth.",
        },
        [RouteName.capitalInvestment]: {
            1: "Capital Investment",
            2: "Quantum Trans Capital’s Capital Investment business line focuses on creating significant value through strategic equity placements and dedicated asset management solutions. We partner with organizations and shareholders to drive growth and stability, offering access to tailored investment opportunities.",
            3: "Our team ensures that every equity placement aligns with our rigorous criteria for long-term viability and profitability, fostering sustainable value for our investors. Through expert asset management, QTC prioritizes balanced portfolios that generate strong returns while managing risk.",
        },
        [RouteName.epc]: {
            1: "EPC (Engineering, Procurement, and Construction)",
            2: "In the EPC sector, QTC provides seamless, integrated engineering, procurement, and construction services to take projects from concept to completion. Our experts oversee every phase, from detailed engineering design to sourcing high-quality materials and managing installation processes.",
            3: "By maintaining strict standards in safety, quality, and efficiency, we ensure that each project we undertake not only meets but exceeds industry standards. This all-encompassing approach allows our clients to benefit from streamlined processes, faster project timelines, and cost-effective outcomes.",
        },
        [RouteName.renewableEnergy]: {
            1: "Renewable Energy",
            2: "Committed to a sustainable future, QTC's Renewable Energy unit delivers innovative solutions that reduce environmental impact while enhancing energy efficiency. Specializing in solar panels, lithium battery storage systems, and LED lighting, our offerings cater to both commercial and residential clients aiming to adopt cleaner energy.",
            3: "By integrating advanced technology with green practices, QTC helps clients reduce carbon emissions and transition smoothly toward sustainable energy sources that lower operational costs and contribute positively to the environment.",
        },
        [RouteName.industrial]: {
            1: "Industrial",
            2: "QTC's Industrial Solutions division supports diverse sectors with cutting-edge manufacturing and production capabilities, helping businesses elevate efficiency and meet industry demands.",
            3: "We combine industry expertise with advanced technology to deliver robust industrial services tailored to the specific needs of our clients. By providing custom solutions, we enable partners to optimize productivity, enhance quality control, and streamline operations, resulting in maximized operational effectiveness and minimized costs.",
        },
        [RouteName.integratedTelecomunication]: {
            1: "Integrated Telecommunication",
            2: "Our Integrated Telecommunications segment focuses on empowering businesses and communities with reliable, high-speed internet and hotspot services, supported by innovative IoT solutions.",
            3: "With a comprehensive approach to connectivity, QTC helps organizations and individuals stay seamlessly connected in an increasingly digital world. We provide advanced telecommunications solutions that not only meet current demands but also pave the way for future digital transformations by enabling smart, connected environments.",
        },
        [RouteName.realEstateProperty]: {
            1: "Real Estate & Property",
            2: "Quantum Trans Capital's Real Estate & Property branch develops and manages high-quality commercial buildings and shophouses strategically located in thriving areas.",
            3: "With a commitment to sustainable and functional design, our properties are tailored to meet the diverse needs of modern businesses and entrepreneurs. Each development prioritizes accessibility, style, and durability, creating spaces that attract clients and elevate their business presence in the market.",
        },
        [RouteName.developer]: {
            1: "Developer",
            2: "As a trusted property developer, QTC focuses on creating residential communities and apartments that blend Contact contemporary design with essential comforts. Our developments are crafted with the needs of urban dwellers in mind, offering a range of modern amenities and accessible locations.",
            3: "We emphasize sustainability and community, ensuring that each property provides a lasting, high-quality living experience that meets the expectations of discerning residents and property investors alike.",
        },
        [RouteName.researchDevelopment]: {
            1: "Research & Development",
            2: "QTC's Research & Development arm is dedicated to driving innovation and growth across all our business lines. Our team focuses on sales strategies, market analysis, and business development initiatives that bring insight-driven solutions to the forefront.",
            3: "By identifying market trends and optimizing operations, we help QTC stay at the cutting edge of each industry we Contact Career operate in. The R&D team continually explores new avenues to enhance customer experience, expand market reach, and bolster our competitive advantage.",
        },
        [RouteName.businessHightlights]: {
            1.1: "Connect evolving needs with",
            1.2: "sustainable innovation opportunities",
            2: "Business Highlights",
            3: "Quantum Trans Capital continues to support the latest renewable energy-based developments",
            4: "Across the various sectors we operate and enable, we strive to deliver excellence and long-term sustainable value as we strengthen Quantum Trans Capital's position as a leading regional and international economic, commercial and renewable energy hub.",
            5: "We embrace innovative projects and work closely with strategic partners to enrich the lives of people, communities as a whole for a better tomorrow.",
            6: "Over the years, we have delivered leading projects and destinations as well as untapped opportunities through key partnerships and investments in the region and beyond. Here we believe that what we are doing is an innovation of sustainability and an excellent contribution to the sustainability of the Republic of Indonesia.",
            7: "Delivering sustainable value across various sectors",
        },
        [RouteName.sustainability]: {
            1.1: "Commitment, Strategy, and",
            1.2: "Partnerships for Sustainable Success",
            2: "Sustainability in Action",
            3: "Quantum Trans Capital is committed to driving long-term success by integrating sustainability principles across all aspects of its operations. With a strategy focused on innovation and efficiency, the company prioritizes the use of cutting-edge technology and responsible resource management.",
            4: "Through strong partnerships and synergy with key stakeholders, Quantum Trans Capital aims to create a lasting positive impact, foster inclusive economic growth, and contribute to the development of environmentally and socially responsible sectors. With a forward-looking approach to sustainability, Quantum Trans Capital is well-positioned to tackle future challenges and deliver value for all involved.",
        },
        [RouteName.sustainabilityCommitment]: {
            1: "Quantum Trans Capital",
            2: "Commitment",
            3: "As a holding investment company, we aim to connect investors, entrepreneurs, and innovative projects to foster growth and positively impact the Indonesian economy.",
            4: "Sustainability guides our operations, ensuring our practices are efficient, environmentally responsible, and socially conscious. We focus on renewable energy and sustainable real estate to build a better future. We prioritize sustainable value for all stakeholders, enhancing performance within our group to benefit everyone involved. Diversifying the economy is key to strengthening Indonesia’s resilience and global competitiveness.",
            5: "Leading with ethics and transparency, we foster trustworthy partnerships. We are also committed to empowering local communities through inclusive economic growth and impactful investments.",
            6: "Through these efforts, Quantum Trans Capital strives to be a leader in creating a sustainable and innovative investment ecosystem in Indonesia.",
        },
        [RouteName.sustainabilityStrategic]: {
            1: "Quantum Trans Capital",
            2: "Our Strategic at QTC",
            3: "Sustainable Investment: Diversification, ESG, Innovation & Positive Impact",
            4: "We are committed to becoming a leader in sustainable investment through a diversified portfolio in English sectors like renewable energy, real estate, technology, and manufacturing to minimize risk and maximize growth.",
            5: "Building strong partnerships with local, international investors and emphasizing socially and environmentally responsible investments are our top priorities. We integrate ESG criteria into every decision.",
            6: "Innovation and technology are key to our strategy, as we support startups tackling significant challenges.Market research informs our investment decisions.",
            7: "We empower entrepreneurs through capacity-building programs and engage with local communities for positive social impact. Our robust risk management and performance monitoring protect stakeholder interests.",
            8: "Through effective communication, we aim to strengthen our brand as a leader in sustainable investment, creating an innovative ecosystem that drives growth for all.",
        },
        [RouteName.sustainabilityPartnerships]: {
            1: "Quantum Trans Capital",
            2: "Partnerships",
            3: "Building Strong Partnerships for Sustainable Growth and Innovation",
            4: "Quantum Trans Capital views partnerships as a key element of our growth strategy. We are committed to building strong relationships with investors, entrepreneurs, and industry leaders, both locally and internationally.",
            5: "We believe that collaboration can drive innovation and create greater opportunities. By adhering to principles of ethics and transparency, we foster an environment of mutual respect.",
            6: "Through strategic partnerships, we integrate resources to develop projects that are financially beneficial and positively impacts society and the environment. Our main focus is on sustainable sectors like renewable energy.",
            7: "We also support the capacity development of our partners, providing access to opportunities and resources to strengthen their market position. In this way, Quantum Trans Capital aims to create a sustainable and innovative investment ecosystem for a more united future.",
        },
        [RouteName.media]: {
            1.1: "Quantum Trans Capital:",
            1.2: "News and Updates",
            2: "Press Release",
            3: "Find news by title",
            4: "Category",
            5: "Year",
            6: "Month",
            7: "Search News",
            8: "Next",
        },
        [RouteName.contact]: {
            1.1: "Connect with",
            1.2: "Quantum Trans Capital",
            2: "Contact Us :",
            3: "For inquiries or more information about our services and opportunities, please reach out via the contact form below or use the details provided.",
            4: "Our team is ready to assist you, and we look forward to connecting !",
            5: "GET IN TOUCH",
            6: "Head Office",
            7: "Connect with Us",
            8: "Here you can send us an inquiry concering general questions",
            9: "First Name",
            10: "Last Name",
            11: "Email Address",
            12: "Contact Number",
            13: "INQUIRY TYPE",
            14: "FILE ATTACHMENT",
            15: "Subject",
            16: "Message",
            17: "Upload File",
            18: "Submit",
        },
        [RouteName.career]: {
            1: "Career",
            2: "Career Opporturnies",
            3: "At Quantum Trans Capital , we believe that the success of our company starts with a strong and committed team. We strive to create a work environment that fosters professional development, innovation, and collaboration.",
            4: "As a growing holding company, we are always on the lookout for talented individuals who are passionate about contributing to our long-term value creation through sustainable investments and positive impact.",
            5: "Explore our current job opportunities",
        },
        [RouteName.careerExplore]: {
            1: "Career",
            2.1: "Explore our current",
            2.2: "job opportunities",
            3: "FIND JOBS",
            4: "Job title, skill, keyword",
            5: "NEAR LOCATION",
            6: "City, state, country",
            7: "ALL JOBS",
        },
        [RouteName.careerList]: {
            1: "Career",
            2: "Why Join Us ?",
            3.1: "Career Development :",
            3.2: "We support the growth and development of every individual through continuous training, mentoring, and long-term capacity-building opportunities.",
            4.1: "Collaborative Environment :",
            4.2: "At Quantum Trans Capital, we believe that teamwork is the foundation for achieving shared goals and creating innovative solutions.",
            5.1: "Social and Environmental Impact :",
            5.2: "We are committed to investing in sectors that are not only financially rewarding but also create a positive impact on society and the environment.",
            6.1: "Global Opportunities :",
            6.2: "As a holding company operating across multiple sectors and international markets, we offer opportunities for employees to work in diverse global environments.",
            7: "If you are passionate about contributing to a sustainable and innovative investment ecosystem, we invite you to explore our career opportunities and join our dynamic team.",
            8: "Explore our current job opportunities",
        },
    },
    id: {
        navbar: {
            1: "Siapa Kami",
            2: "Dampak Kami",
            2.1: "Investasi Modal",
            2.2: "EPC",
            2.3: "Energi Terbarukan",
            2.4: "Industri",
            2.5: "Telekomunikasi Terpadu",
            2.6: "Real Estat & Properti",
            2.7: "Pengembang",
            2.8: "Penelitian dan Pengembangan",
            3: "Sorotan Bisnis",
            4: "Keberlanjutan",
            4.1: "Komitmen",
            4.2: "Strategi Kami di QTC",
            4.3: "Kemitraan",
            5: "Media",
            5.1: "Siaran Pers",
            5.2: "Aset Pemasaran",
            6: "Kontak",
            7: "Karier",
            7.1: "Peluang Karier",
            7.2: "Mengapa Bergabung dengan Kami",
            7.3: "Jelajahi Pekerjaan Kami"
        },
        [RouteName.main]: {
            1: "Quantum Trans Capital",
            2.1: "Berbagi Peluang Investasi",
            2.2: "Di Seluruh Indonesia",
            3: "Tentang Kami",
            4.1: "Menghubungkan Investor Global dan",
            4.2: "Proyek Berkelanjutan Waktu Nyata di Indonesia",
            5.1: "Perusahaan Holding yang Tangguh dan Aman",
            5.2: "dengan",
            5.3: "Aset Operasional yang",
            5.4: "Berkaitan dengan Energi yang Beragam",
            6: "Quantum Trans Capital, kami mendorong perubahan positif dalam ekonomi Indonesia dengan menjembatani proyek-proyek visioner dengan investor strategis.",
            7: "Fokus kami pada investasi modal, energi terbarukan, real estat, dan kebutuhan industri mendorong pertumbuhan, inovasi, dan keberlanjutan. Sebagai katalisator kemajuan, Quantum Trans Capital menghubungkan peluang dan membentuk masa depan yang lebih cerah.",
            8: "Dampak Kami",
            9: "Quantum Trans Capital adalah perusahaan Indonesia yang mengkhususkan diri dalam memfasilitasi dan mendanai peluang investasi di berbagai sektor.",
            10: "Sorotan Bisnis",
            11: "Quantum Trans Capital telah membawa proyek-proyek unggulan, destinasi, dan peluang yang belum tergarap ke pasar melalui kemitraan dan investasi utama di wilayah ini dan sekitarnya.",
            12: "Energi Terbarukan",
            13: "Keberlanjutan",
            14: "<b>Quantum Trans Capital,</b> keberlanjutan adalah prinsip dasar yang mendasari arah strategis dan keunggulan operasional kami.",
            15: "Kami berkomitmen untuk memberikan nilai yang berkelanjutan bagi para pemangku kepentingan kami, sambil meningkatkan kinerja setiap lini perusahaan.",
            16: "Bisnis kami dipandu oleh prinsip keberlanjutan yang membentuk arah strategis dan keunggulan operasional kami.",
            17: "Keberlanjutan",
            18: "Ikuti semua berita menarik dan peluang saat kami memperluas jaringan, memasuki pasar baru, menjalin kemitraan baru, dan memanfaatkan sumber daya terbaru kami.",
            19: "Jelajahi pusat media kami",
            20: "Siaran pers dan sumber daya",
        },
        [RouteName.about]: {
            1.1: "Menghubungkan kebutuhan yang berkembang dengan",
            1.2: "peluang inovasi berkelanjutan",
            2: "Siapa kami",
            3.1: "Berinvestasi untuk Pertumbuhan Berkelanjutan",
            3.2: "di Indonesia",
            4: "Quantum Trans Capital adalah perusahaan global yang dinamis dan serbaguna yang mengkhususkan diri dalam menjembatani dan mendanai peluang investasi di berbagai sektor.",
            5: "Quantum Trans Capital juga merupakan PMA dari Hong Kong.",
            6: "Misi kami adalah untuk memfasilitasi pertumbuhan, inovasi, dan pembangunan berkelanjutan dengan menghubungkan investor, wirausahawan, dan proyek.",
            7: "Dengan fokus pada modal, energi terbarukan, properti, dan kebutuhan industri lainnya, Quantum Trans Capital memainkan peran penting dalam membentuk lanskap ekonomi global.",
            8: "Visi Kami",
            9: "Quantum Trans Capital membayangkan Indonesia yang makmur di mana investasi berkelanjutan mendorong pertumbuhan ekonomi, meningkatkan kesejahteraan, dan melindungi lingkungan.",
            10: "Kami berusaha menjadi katalis perubahan positif, mendorong inovasi dan ketahanan.",
            11: "Misi Kami",
            12: "Quantum Trans Capital berkomitmen untuk mengubah lanskap ekonomi Indonesia melalui investasi strategis yang berdampak tinggi.",
            13: "Misi kami adalah untuk menjembatani kesenjangan antara proyek visioner dan investor strategis, mendorong pertumbuhan berkelanjutan, inovasi finansial, dan penciptaan nilai jangka panjang. Memanfaatkan keahlian kami dalam pasar modal, manajemen aset, dan pembiayaan yang berfokus pada sektor energi terbarukan, properti, dan pengembangan industri, kami berdedikasi untuk memberikan hasil yang terukur sambil memastikan pertumbuhan yang bertanggung jawab.",
            14: "Quantum Trans Capital berdiri sebagai katalis untuk kemajuan, menghubungkan peluang dan membentuk masa depan yang lebih cerah.",
            15: "Kepemimpinan Quantum Trans Capital",
            16: "Orang-orang kami adalah",
            17: "kekuatan terbesar kami",
            18: "Quantum Trans Capital fokus untuk membangun ekosistem global yang mendorong pertumbuhan, teknologi, dan peluang inovasi bagi pemegang saham, perusahaan, dan negara.",
        },
        [RouteName.ourImpact]: {
            1.1: "Mendorong Inovasi Berkelanjutan:",
            1.2: "Dampak Quantum Trans Capital",
            2: "Dampak Kami",
            3: "Melalui pengelolaan portofolio aset yang terdiversifikasi dan terintegrasi secara strategis, Quantum Trans Capital berkomitmen untuk mendorong pertumbuhan ekonomi yang berkelanjutan dan memajukan inovasi di sektor industri utama. Dipandu oleh strategi investasi yang disiplin dengan penekanan kuat pada prinsip ESG, kami fokus pada penciptaan nilai jangka panjang bagi pemangku kepentingan sambil menghasilkan manfaat sosial dan lingkungan yang bermakna.",
            4: "Perusahaan-perusahaan dalam portofolio Quantum Trans Capital merupakan pemimpin industri yang diakui karena pendekatan inovatif dan berani mereka. Berkomitmen untuk memajukan kemampuan teknologi dan menerapkan praktik terbaik, mereka mendorong dampak positif yang luas di berbagai sektor. Dengan memprioritaskan kemitraan strategis dan keberlanjutan, Quantum Trans Capital secara konsisten mencari solusi untuk mengatasi tantangan pasar modern, memberikan kontribusi nyata terhadap pertumbuhan ekonomi yang inklusif dan berkelanjutan.",
        },
        [RouteName.capitalInvestment]: {
            1: "Investasi Modal",
            2: "Lini bisnis Investasi Modal Quantum Trans Capital fokus pada penciptaan nilai signifikan melalui penempatan ekuitas strategis dan solusi manajemen aset yang terdedikasi. Kami bermitra dengan organisasi dan pemegang saham untuk mendorong pertumbuhan dan stabilitas, menawarkan akses ke peluang investasi yang disesuaikan.",
            3: "Tim kami memastikan bahwa setiap penempatan ekuitas sesuai dengan kriteria ketat kami untuk kelayakan dan profitabilitas jangka panjang, mendorong nilai berkelanjutan bagi para investor kami. Melalui manajemen aset yang ahli, QTC memprioritaskan portofolio seimbang yang menghasilkan pengembalian yang kuat sambil mengelola risiko.",
        },
        [RouteName.epc]: {
            1: "EPC (Engineering, Procurement, and Construction)",
            2: "Di sektor EPC, QTC menyediakan layanan rekayasa, pengadaan, dan konstruksi terintegrasi yang mulus untuk membawa proyek dari konsep hingga selesai. Para ahli kami mengawasi setiap fase, dari desain rekayasa rinci hingga pengadaan material berkualitas tinggi dan mengelola proses instalasi.",
            3: "Dengan mempertahankan standar ketat dalam keselamatan, kualitas, dan efisiensi, kami memastikan bahwa setiap proyek yang kami jalankan tidak hanya memenuhi tetapi melebihi standar industri. Pendekatan menyeluruh ini memungkinkan klien kami untuk memperoleh manfaat dari proses yang lebih efisien, waktu proyek yang lebih cepat, dan hasil yang hemat biaya.",
        },
        [RouteName.renewableEnergy]: {
            1: "Energi Terbarukan",
            2: "Berkomitmen untuk masa depan yang berkelanjutan, unit Energi Terbarukan QTC menyediakan solusi inovatif yang mengurangi dampak lingkungan sambil meningkatkan efisiensi energi. Mengkhususkan diri dalam panel surya, sistem penyimpanan baterai litium, dan pencahayaan LED, penawaran kami melayani klien komersial dan residensial yang bertujuan mengadopsi energi yang lebih bersih.",
            3: "Dengan mengintegrasikan teknologi canggih dengan praktik ramah lingkungan, QTC membantu klien mengurangi emisi karbon dan beralih dengan lancar menuju sumber energi berkelanjutan yang menurunkan biaya operasional dan memberikan kontribusi positif terhadap lingkungan.",
        },
        [RouteName.industrial]: {
            1: "Industri",
            2: "Divisi Solusi Industri QTC mendukung berbagai sektor dengan kemampuan manufaktur dan produksi mutakhir, membantu bisnis meningkatkan efisiensi dan memenuhi tuntutan industri.",
            3: "Kami menggabungkan keahlian industri dengan teknologi canggih untuk memberikan layanan industri yang kuat yang disesuaikan dengan kebutuhan spesifik klien kami. Dengan menyediakan solusi khusus, kami memungkinkan mitra untuk mengoptimalkan produktivitas, meningkatkan pengendalian kualitas, dan menyederhanakan operasi, yang menghasilkan efektivitas operasional yang maksimal dan biaya yang diminimalkan.",
        },
        [RouteName.integratedTelecomunication]: {
            1: "Telekomunikasi Terintegrasi",
            2: "Segmen Telekomunikasi Terintegrasi kami fokus pada memberdayakan bisnis dan komunitas dengan layanan internet berkecepatan tinggi dan hotspot yang andal, didukung oleh solusi IoT yang inovatif.",
            3: "Dengan pendekatan komprehensif terhadap konektivitas, QTC membantu organisasi dan individu tetap terhubung tanpa hambatan di dunia yang semakin digital. Kami menyediakan solusi telekomunikasi canggih yang tidak hanya memenuhi tuntutan saat ini tetapi juga membuka jalan untuk transformasi digital di masa depan dengan memungkinkan lingkungan yang cerdas dan terhubung.",
        },
        [RouteName.realEstateProperty]: {
            1: "Properti & Real Estat",
            2: "Divisi Properti & Real Estat Quantum Trans Capital mengembangkan dan mengelola gedung komersial berkualitas tinggi dan rumah toko yang terletak strategis di daerah yang berkembang pesat.",
            3: "Dengan komitmen pada desain yang berkelanjutan dan fungsional, properti kami disesuaikan untuk memenuhi berbagai kebutuhan bisnis dan wirausahawan modern. Setiap pengembangan memprioritaskan aksesibilitas, gaya, dan daya tahan, menciptakan ruang yang menarik bagi klien dan meningkatkan kehadiran bisnis mereka di pasar.",
        },
        [RouteName.developer]: {
            1: "Pengembang",
            2: "Sebagai pengembang properti yang tepercaya, QTC fokus pada penciptaan komunitas perumahan dan apartemen yang memadukan desain kontemporer dengan kenyamanan yang esensial. Pengembangan kami dirancang dengan mempertimbangkan kebutuhan penghuni kota, menawarkan berbagai fasilitas modern dan lokasi yang mudah dijangkau.",
            3: "Kami menekankan keberlanjutan dan komunitas, memastikan bahwa setiap properti memberikan pengalaman hunian berkualitas tinggi yang tahan lama dan memenuhi harapan penghuni yang cermat serta investor properti.",
        },
        [RouteName.researchDevelopment]: {
            1: "Riset & Pengembangan",
            2: "Divisi Riset & Pengembangan QTC berkomitmen untuk mendorong inovasi dan pertumbuhan di semua lini bisnis kami. Tim kami fokus pada strategi penjualan, analisis pasar, dan inisiatif pengembangan bisnis yang membawa solusi berbasis wawasan ke garis depan.",
            3: "Dengan mengidentifikasi tren pasar dan mengoptimalkan operasi, kami membantu QTC tetap berada di garis depan setiap industri yang kami geluti. Tim R&D terus mengeksplorasi jalur baru untuk meningkatkan pengalaman pelanggan, memperluas jangkauan pasar, dan memperkuat keunggulan kompetitif kami.",
        },
        [RouteName.businessHightlights]: {
            1.1: "Menghubungkan kebutuhan yang berkembang dengan",
            1.2: "peluang inovasi berkelanjutan",
            2: "Sorotan Bisnis",
            3: "Quantum Trans Capital terus mendukung pengembangan berbasis energi terbarukan terbaru",
            4: "Di berbagai sektor yang kami jalankan dan fasilitasi, kami berusaha untuk memberikan keunggulan dan nilai berkelanjutan jangka panjang saat kami memperkuat posisi Quantum Trans Capital sebagai pusat ekonomi, komersial, dan energi terbarukan terkemuka di regional dan internasional.",
            5: "Kami merangkul proyek inovatif dan bekerja sama dengan mitra strategis untuk memperkaya kehidupan orang-orang dan komunitas secara keseluruhan demi masa depan yang lebih baik.",
            6: "Selama bertahun-tahun, kami telah menghasilkan proyek-proyek terkemuka dan destinasi serta peluang yang belum tergarap melalui kemitraan utama dan investasi di wilayah ini dan sekitarnya. Di sini kami percaya bahwa apa yang kami lakukan adalah inovasi keberlanjutan dan kontribusi luar biasa terhadap keberlanjutan Republik Indonesia.",
            7: "Menyampaikan nilai berkelanjutan di berbagai sektor",
        },
        [RouteName.sustainability]: {
            1.1: "Komitmen, Strategi, dan",
            1.2: "Kemitraan untuk Keberhasilan Berkelanjutan",
            2: "Keberlanjutan dalam Tindakan",
            3: "Quantum Trans Capital berkomitmen untuk mendorong kesuksesan jangka panjang dengan mengintegrasikan prinsip keberlanjutan ke dalam seluruh aspek operasinya. Dengan strategi yang berfokus pada inovasi dan efisiensi, perusahaan memprioritaskan penggunaan teknologi mutakhir dan pengelolaan sumber daya yang bertanggung jawab.",
            4: "Melalui kemitraan yang kuat dan sinergi dengan pemangku kepentingan utama, Quantum Trans Capital bertujuan untuk menciptakan dampak positif yang langgeng, mendorong pertumbuhan ekonomi inklusif, dan berkontribusi pada pengembangan sektor-sektor yang ramah lingkungan dan sosial. Dengan pendekatan yang berfokus pada masa depan, Quantum Trans Capital berada dalam posisi yang baik untuk menghadapi tantangan masa depan dan memberikan nilai bagi semua pihak yang terlibat.",
        },
        [RouteName.sustainabilityCommitment]: {
            1: "Quantum Trans Capital",
            2: "Komitmen",
            3: "Sebagai perusahaan investasi induk, kami bertujuan untuk menghubungkan investor, pengusaha, dan proyek-proyek inovatif untuk mendorong pertumbuhan dan memberikan dampak positif bagi perekonomian Indonesia.",
            4: "Keberlanjutan menjadi pedoman dalam operasi kami, memastikan bahwa praktik kami efisien, bertanggung jawab terhadap lingkungan, dan sadar sosial. Kami fokus pada energi terbarukan dan real estat berkelanjutan untuk membangun masa depan yang lebih baik. Kami memprioritaskan nilai berkelanjutan bagi semua pemangku kepentingan, meningkatkan kinerja dalam grup kami untuk memberikan manfaat bagi semua pihak yang terlibat. Diversifikasi ekonomi adalah kunci untuk memperkuat ketahanan Indonesia dan daya saing global.",
            5: "Dengan memimpin dengan etika dan transparansi, kami membangun kemitraan yang dapat dipercaya. Kami juga berkomitmen untuk memberdayakan komunitas lokal melalui pertumbuhan ekonomi yang inklusif dan investasi yang berdampak.",
            6: "Melalui upaya ini, Quantum Trans Capital berusaha untuk menjadi pemimpin dalam menciptakan ekosistem investasi yang berkelanjutan dan inovatif di Indonesia."
        },
        [RouteName.sustainabilityStrategic]: {
            1: "Quantum Trans Capital",
            2: "Strategi Kami di QTC",
            3: "Investasi Berkelanjutan: Diversifikasi, ESG, Inovasi & Dampak Positif",
            4: "Kami berkomitmen untuk menjadi pemimpin dalam investasi berkelanjutan melalui portofolio yang terdiversifikasi di sektor-sektor seperti energi terbarukan, real estat, teknologi, dan manufaktur untuk meminimalkan risiko dan memaksimalkan pertumbuhan.",
            5: "Membangun kemitraan yang kuat dengan investor lokal dan internasional serta menekankan investasi yang bertanggung jawab secara sosial dan lingkungan adalah prioritas utama kami. Kami mengintegrasikan kriteria ESG dalam setiap keputusan.",
            6: "Inovasi dan teknologi adalah kunci strategi kami, karena kami mendukung startup yang mengatasi tantangan besar. Riset pasar memberikan wawasan untuk pengambilan keputusan investasi kami.",
            7: "Kami memberdayakan pengusaha melalui program pembangunan kapasitas dan berinteraksi dengan komunitas lokal untuk dampak sosial yang positif. Manajemen risiko dan pemantauan kinerja yang kuat melindungi kepentingan pemangku kepentingan.",
            8: "Melalui komunikasi yang efektif, kami bertujuan untuk memperkuat merek kami sebagai pemimpin dalam investasi berkelanjutan, menciptakan ekosistem inovatif yang mendorong pertumbuhan untuk semua."
        },
        [RouteName.sustainabilityPartnerships]: {
            1: "Quantum Trans Capital",
            2: "Kemitraan",
            3: "Membangun Kemitraan yang Kuat untuk Pertumbuhan dan Inovasi Berkelanjutan",
            4: "Quantum Trans Capital melihat kemitraan sebagai elemen kunci dari strategi pertumbuhan kami. Kami berkomitmen untuk membangun hubungan yang kuat dengan investor, pengusaha, dan pemimpin industri, baik secara lokal maupun internasional.",
            5: "Kami percaya bahwa kolaborasi dapat mendorong inovasi dan menciptakan peluang yang lebih besar. Dengan mematuhi prinsip etika dan transparansi, kami menciptakan lingkungan yang saling menghormati.",
            6: "Melalui kemitraan strategis, kami mengintegrasikan sumber daya untuk mengembangkan proyek-proyek yang menguntungkan secara finansial dan memberikan dampak positif bagi masyarakat dan lingkungan. Fokus utama kami adalah pada sektor-sektor berkelanjutan seperti energi terbarukan.",
            7: "Kami juga mendukung pengembangan kapasitas mitra kami, memberikan akses ke peluang dan sumber daya untuk memperkuat posisi pasar mereka. Dengan cara ini, Quantum Trans Capital bertujuan untuk menciptakan ekosistem investasi yang berkelanjutan dan inovatif untuk masa depan yang lebih bersatu."
        },
        [RouteName.media]: {
            1.1: "Quantum Trans Capital:",
            1.2: "Berita dan Pembaruan",
            2: "Siaran Pers",
            3: "Temukan berita berdasarkan judul",
            4: "Kategori",
            5: "Tahun",
            6: "Bulan",
            7: "Cari Berita",
            8: "Selanjutnya"
        },
        [RouteName.contact]: {
            1.1: "Hubungi",
            1.2: "Quantum Trans Capital",
            2: "Kontak Kami:",
            3: "Untuk pertanyaan atau informasi lebih lanjut mengenai layanan dan peluang kami, silakan hubungi melalui formulir kontak di bawah ini atau gunakan detail yang disediakan.",
            4: "Tim kami siap membantu Anda, dan kami menantikan untuk dapat terhubung!",
            5: "HUBUNGI KAMI",
            6: "Kantor Pusat",
            7: "Hubungi Kami",
            8: "Di sini Anda dapat mengirimkan pertanyaan terkait pertanyaan umum",
            9: "Nama Depan",
            10: "Nama Belakang",
            11: "Alamat Email",
            12: "Nomor Kontak",
            13: "JENIS PERTANYAAN",
            14: "LAMPIRAN BERKAS",
            15: "Subjek",
            16: "Pesan",
            17: "Unggah Berkas",
            18: "Kirim"
        },
        [RouteName.career]: {
            1: "Karir",
            2: "Peluang Karir",
            3: "Di Quantum Trans Capital, kami percaya bahwa kesuksesan perusahaan kami dimulai dengan tim yang kuat dan berdedikasi. Kami berusaha menciptakan lingkungan kerja yang mendukung pengembangan profesional, inovasi, dan kolaborasi.",
            4: "Sebagai perusahaan induk yang berkembang, kami selalu mencari individu berbakat yang memiliki semangat untuk berkontribusi pada penciptaan nilai jangka panjang kami melalui investasi berkelanjutan dan dampak positif.",
            5: "Jelajahi peluang pekerjaan kami saat ini"
        },
        [RouteName.careerExplore]: {
            1: "Karir",
            2.1: "Jelajahi",
            2.2: "peluang pekerjaan kami saat ini",
            3: "CARI PEKERJAAN",
            4: "Judul pekerjaan, keterampilan, kata kunci",
            5: "LOKASI TERDEKAT",
            6: "Kota, provinsi, negara",
            7: "SEMUA PEKERJAAN"
        },
        [RouteName.careerList]: {
            1: "Karir",
            2: "Mengapa Bergabung dengan Kami?",
            3.1: "Pengembangan Karir:",
            3.2: "Kami mendukung pertumbuhan dan pengembangan setiap individu melalui pelatihan berkelanjutan, pembimbingan, dan peluang pembangunan kapasitas jangka panjang.",
            4.1: "Lingkungan Kolaboratif:",
            4.2: "Di Quantum Trans Capital, kami percaya bahwa kerja tim adalah dasar untuk mencapai tujuan bersama dan menciptakan solusi inovatif.",
            5.1: "Dampak Sosial dan Lingkungan:",
            5.2: "Kami berkomitmen untuk berinvestasi di sektor-sektor yang tidak hanya menguntungkan secara finansial, tetapi juga menciptakan dampak positif bagi masyarakat dan lingkungan.",
            6.1: "Peluang Global:",
            6.2: "Sebagai perusahaan induk yang beroperasi di berbagai sektor dan pasar internasional, kami menawarkan peluang bagi karyawan untuk bekerja di lingkungan global yang beragam.",
            7: "Jika Anda memiliki semangat untuk berkontribusi pada ekosistem investasi yang berkelanjutan dan inovatif, kami mengundang Anda untuk menjelajahi peluang karir kami dan bergabung dengan tim dinamis kami.",
            8: "Jelajahi peluang pekerjaan kami saat ini"
        },
    },
}